<!-- Últimas Liquidaciones APDS -->

<template>
  <div class="apds_liquidacion_ultimas"> 
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">  
        <v-btn 
          v-on='on'
          v-on:click="ini_component"
          v-bind="$cfg.btra_cfg.standard"
          title="LIQUIDACIONES APDS">
            <v-icon>{{'mdi-format-list-bulleted'}}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="contenedor conflex" style="width:600px"> 
            <div class="columna">
            
              <!-- Grid -->
              <base_Fgrid
                v-model="selected"
                :padre="stName" 
                :Entorno="Entorno"  
                height="400px"        
                :items-per-page=-1 
                show-select 
                single-select
                hide-default-footer 
                fixed-header     
                dense>

                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ }">         
                  <div style="padding:10px 0 0 10px;">
                    <div style="display:flex;justify-content:space-between;width:280px;padding-bottom:15px">
                      <v-btn
                        v-bind="$cfg.btra_cfg.standard"            
                        title="Ver Liquidación"
                        @click="ver_liquida">
                          <v-icon dark>{{ "mdi-check" }}</v-icon>
                      </v-btn>  
                    </div>           
                  </div>
                </template>             
              </base_Fgrid>
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
      
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid },
    props: {
      padre: { type:String, default: '' }, 
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {}
        },
        api:'',
        stName:'stFapds_liquidacion_ultimas',
        headers: {},
        selected:[],
        btra_permisos:{ extra:{}},

        dialog:false
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Liquidaciones APDS"; 
        this.Entorno.grid.autoload= false;

        this.headers = {
          header: [
            { text: "Liquidacion", value: "liq", width: "20%" },
            { text: "Fecha", value: "fh", width: "20%" , filtro:'fecha'},
            { text: "Apds", value: "apds", width: "10%" },
            { text: "Exped", value: "exped", width: "10%" },
            { text: "Total", value: "tot", width: "20%", filtro:'num' },
            { text: "Asiento", value: "asi", width: "15%" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      },


      // obtengo los registros a mostrar
      async ini_component() {        
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'lista'          
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },


      //
      ver_liquida() {
        if (!this.selected.length) return;
      
        this.dialog= false;
        this.$emit('onEvent', { liq: this.selected[0].liq });
      }

    }
  };
</script>
